@import "../../global.scss";




.portfolioList{
    font-size: 20px;
    color: white;
    margin: 5px;
    padding: 0.7em;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    @media (min-width: 1201px) and (max-width: 1680px){
        margin: 4px; 
        font-size: 18px;
    }
    @media (min-width: 992px) and (max-width: 1200px){
        margin: 3px; 
        font-size: 16px;     
    }
    @media (min-width: 768px) and (max-width: 991px){
        margin: 2px; 
        font-size: 14px;        
    }
    @media(max-width:768px){
        margin: 1px; 
        font-size: 10px;

    }
    

    &.active{
        background-color: $mainColor;
        color: #e2a177;
    }
}
