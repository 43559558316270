@import "../../global.scss";


.portfolio{
    background-color: $mainColor;
    display: flex;
    flex-direction: column;
    align-items: center;


    h1{
        font-size: 50px;
        overflow: hidden;
        color: white;
        @media (min-width: 1201px) and (max-width: 1680px){
            font-size: 35px;
 
        }
        @media (min-width: 992px) and (max-width: 1200px){
            font-size: 30px;
      
        }
        @media (min-width: 768px) and (max-width: 991px){
            font-size: 25px;
     
        }
        @media(max-width:768px){
            font-size: 25px;
      
        }

    }


    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        overflow: hidden;

    }


    .rocket{
        img{
            position: absolute;
            width: 100%;
            left: 0px;

        }
    }


    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;


        .item{
            width: 400px;
            height: 280px;
            border-radius: 20px;
            border: 1px solid lightslategray;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all 0.5s ease ;
            cursor: pointer;
            @media (min-width: 1680px){
                font-size: 8px;
                width: 300px;
                height: 200px;          
            }
            @media (min-width: 1201px) and (max-width: 1680px){
                font-size: 8px;
                width: 250px;
                height: 150px;          
            }
            @media (min-width: 992px) and (max-width: 1200px){
                font-size: 7px;
                width: 230px;
                height: 140px;          
            }
            @media (min-width: 768px) and (max-width: 991px){
                font-size: 6px;
                width: 200px;
                height: 135px;          
            }
            @media (min-width: 500px) and (max-width: 767px){
                font-size: 3px;
                width: 250px;
                height: 60px;
            }
            @media (max-width: 499px){
                font-size: 3px;
                width: 250px;
                height: 60px;
            }

            // @media (min-height: 800px) and (max-height: 1080px){
            //     font-size: 8px;
            //     width: 300px;
            //     height: 210px;           
            // }
            // @media (min-height: 600px) and (max-height: 799px){
            //     font-size: 8px;
            //     width: 250px;
            //     height: 175px;        
            // }
            // @media (min-height: 400px) and (max-height: 599px){
            //     font-size: 8px;
            //     width: 200px;
            //     height: 100px;
            // }
            // @media(max-height:399px){
            //     font-size: 8px;
            //     width: 150px;
            //     height: 80px;
            // }
            h3{
                position: absolute;
                font-size: 15px;
                justify-content: center;
                align-items: center;
                @media (min-width: 1201px) and (max-width: 1680px){
                    font-size: 12px;
        
                }
                @media (min-width: 992px) and (max-width: 1200px){
                    font-size: 10px;   
                }
                @media (min-width: 768px) and (max-width: 991px){
                    font-size: 10px;
     
                }
                @media (min-width: 500px) and (max-width: 767px){
                    font-size: 9px;

                }
                @media (max-width: 499px){
                    font-size: 9px;

                }

            }
            

            a{

                width: 100%;
                height: 100%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index:1
                }
            }
            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }

    

}