@import "../../global.scss";



.Opening{

    .meteor_shower{
        width: 100vw;
        height: 100vh;
        margin-top: -100px;
    }
    overflow: hidden;
    background-color:$mainColor;
    .bgContainer{
        height: 100%;
        overflow: hidden;

        img{
            height: 100%;
            
            @media(max-width:768px){
                height: 70%;
            }
        }
    }


    .ball1{
        img{
            position: absolute;
            width: 10%;
            bottom: 100px;
            left: 500px;
            
            @media(max-width:768px){
                position: absolute;
                width: 20%;
                bottom: 80px;
                left: 50px;
                        }
        }
    }

    .ball2{
        img{
            position: absolute;
            width: 15%;

            right: 480px;
            bottom: 300px;

            @media(max-width:768px){
                position: absolute;
                width: 50%;
                top: 300px;
                right: 90px;
                        }
        }
    }

    .ball3{
        img{
            position: absolute;
            width: 10%;

            top: 150px;
            left: 500px;

            @media(max-width:768px){
                position: absolute;
                width: 20%;
                top: 50px;
                left: 100px;
                        }
        }
    }

    .raypeng{
        img{
            position: absolute;
            width: 15%;

            top: 180px;
            left: 850px;

            @media(max-width:768px){
                width: 50%;
                top: 180px;
                left: 50px;
                        }
        }
    }

    .slog{
        img{
            position: absolute;
            width: 15%;

            bottom: 380px;
            left: 550px;
        }
    }

    a{
        position: absolute;
        bottom:10px;
        left: 800px;

        
        @media(max-width:768px){
            bottom:10px;
            left: 170px;
                    }
        img{
            width:50px;
            animation: arrowBlink 2s infinite;
        }

        @keyframes arrowBlink {
            100%{
                opacity: 0;
            }
            
        }
    }
}