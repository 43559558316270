@import "../../global.scss";



.intro{
    background-color: $mainColor;
    display: flex;
                
    @media(max-width:768px){
        flex-direction: column;
    }

    .rocket{
        img{
            position: absolute;
            width: 20%;
            left: 50px;
            @media(max-width:768px){
                left: 50px;
          
            }

        }
    }


    
    .star{
        img{
            right: 50px;
            position: absolute;
            width: 60%;
            right: 100px;


            @media(max-width:768px){
                left: 1px;
          
            }
        }
    }

    .left{
        flex: 0.5;
        overflow: hidden;

        .imgContainer{
            overflow: hidden;

            width: 500px;
            height: 800px;
            display: flex;
            align-items: center;
            float: right;
            @media(max-width:768px){
                width: 250px;
                height: 280px;            }
            
            img{
                width: 90%;
                border-radius: 50%;

            }
        }

    }

    .right{
        flex: 0.5;
        overflow: hidden;
        z-index: 100;

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
.writeway{
    text-decoration: none;
    color: burlywood;


    &:hover{
        text-decoration: none;
        color:cornflowerblue;


    }
    &:visited{
        color:cornflowerblue;
        text-decoration: none;

    }
}
            h1{
                font-size:50px;
                margin: 10px 0;
            }
            h2{
                font-size: 35px;
            }
            h3{
                
                font-size: 30px;
                span{
                    font-size: inherit;
                    color: #938bb3;
                }

                
            @media(max-width:768px){
                font-size: 18px;
          
            }

                .ityped-cursor {
                    font-size: 2.2rem;
                    opacity: 1;
                    -webkit-animation: blink 0.3s infinite;
                    -moz-animation: blink 0.3s infinite;
                    animation: blink 0.3s infinite;
                    animation-direction: alternate;
                }
                 
                @keyframes blink {
                    100% {
                        opacity: 0;
                    }
                }
                 
                @-webkit-keyframes blink {
                    100% {
                        opacity: 0;
                    }
                }
                 
                @-moz-keyframes blink {
                    100% {
                        opacity: 0;
                    }
                }

            }
        }
        .smallRocket{
            position: absolute;
            bottom:10px;
            img{
                width:50px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
                
            }
        }
    }
}