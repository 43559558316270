@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top:0;
    z-index:3 ;
    transition:  all 1s ease;



    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width:768px){
        padding: 10px 10px;

        }

            .left{
                display: flex;
                align-items: center;

            .logo{
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;

                @media(max-width:768px){
                    font-size: 20px;
                    margin-right: 5px;

                    }
            }

            .itemcontainer{
                display: flex;
                align-items: center;
                margin-left: 30px;

                @media(max-width:768px){
                    font-size: 10px;
                    margin-left: 5px;
                    }
                span{
                    font-size: 15px;
                    font-weight:500 ;

                    @media(max-width:768px){
                        font-size: 12px;
                        }
                }

            }
        }
    
    
    
        .right{
            .hbg{
                width: 32px;
                height: 25px;
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;


            }

            span{
                width: 100%;
                height: 3px;
                background-color: $mainColor;
                transform-origin: left;
                transition: all 2s ease;
            }
        }
    
    }


    &.active{
        background-color: $mainColor;
        color: white;

        .hbg{
            span{
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);

                }
            }
        }
    }
}