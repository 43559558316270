@import "../../global.scss";



.contact{
    background-color: $mainColor;
    display: flex;



    .right{
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2{
            font-size: 30px;
            color: white;
            margin-bottom: 20px;
        }
            
            form{
                width: 70%;                                         
                height: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                input{
                    width: 300px;
                    height: 30px;
                    font-size: 14px;
                    @media(max-width:768px){
                        width: 260px;
                    }
                }


                textarea{
                    width: 300px;
                    height: 200px;
                    font-size: 14px;
                    @media(max-width:768px){
                        width: 260px;
                    }
                }

                button{
                    width: 150px;
                    height: 30px;
                    color: white;
                    background-color: rgb(122, 24, 24);
                    border: none;
                    border-radius: 10px;
                    font-weight: 500;
                    cursor: pointer;

                    &:focus{
                        outline: none;
                    }


                }

                span{
                    color: rgb(202, 25, 25);
                }
            }
        

    }
}